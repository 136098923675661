// BASE START
body{
    position: absolute;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;

    flex-direction: column;
    color: #646c9a;
    font-size: 13px;
    font-weight: 300;
    font-family: Poppins,cursive;
    line-height: 1.5;
    text-align: left;
}

a {
    color: #5867dd;
    text-decoration: none;
    background-color: transparent;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.btn {
    align-items: center;
    outline: none !important;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 8px 0px;
    justify-content: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    svg{
        width: 20px;
        height: 20px;
    }
}

/* Section */
section{
    position: relative;
    padding: 60px 0px;
    width: 100%;
}


/* Utils */
.hidden{
    display: none;
}




/* Form */
.form-content{
    width: 40rem;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;

    input{
        font-size: 17px;
        margin: 0.4rem;
        padding: 0.9rem 1rem;
        width: 42%;
        border: none;
    }

    .MuiButton-root{
        margin-top: 22px
    }
}

/* Button */

/*
.btn {
    position: relative;
    color: #fff;
    margin-top: 20px;
    font-size: 1.02vw;
    line-height: 1.8vw;
    cursor: pointer;
    width: 9vw;
    text-align: center;
    background: linear-gradient(45deg, #0dacc1, #13bda7);
    border: none;
    border-radius: 5px;
    align-items: center;
    outline: none !important;
    vertical-align: middle;
    padding: 8px 0px;
    justify-content: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    svg{
        width: 20px;
        height: 20px;
    }

}

.btn:hover{
    opacity: 0.8;
}
*/

/* Containers */
.MuiContainer-root{
    position: relative;
    z-index: 2;

    .MuiButtonGroup-root{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
}

section .MuiGrid-root{
    margin-top: 2px;
}

/* Card Styles */
.card-value{
    color: #04B4ED;
    font-size: 20px;
    text-align: center;
    font-size: 7vw;
    margin-bottom: 12px;
}


/* BG Layer */
.bg-layer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 1;
    background: linear-gradient(180deg, #2f1550 0%, #6d1dde 100%);

    .bg{
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.3;

        img{
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/* Video */
video{
    width: 50%;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    z-index: 3;
    margin: auto;
    display: block;
}

/* Video Poster */
.poster-wrap{
    position: relative;
    z-index: 3;
    width: 50%;
    margin: auto;

    .play-btn{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            width: 90px;
            height: 90px;
            fill: rgba(255,255,255,0.9);
        }
    }

    .poster{
        width: auto;
        height: auto;

        img{
            width: 100%;
        }
    }
}

/* Card Items */
.MuiPaper-root{

    picture, video{
        height: 200px;
        width: 100%;
        border-radius: 6px;

        display: block;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .MuiCardContent-root{

        .MuiButtonGroup-root{
            display: block;
            text-align: right;
            margin-top: 10px;
        }

    }
}


/* Pink Btn */
.pink-btn{
    .MuiButton-root{
        background: #c72975;
        padding: 10px 20px;
    }
}

/* Dashboard Styling */
.variant-list{

    .heading{
        font-size: 14px;
        font-family: Amatic SC,cursive;
        text-align: center;
        line-height: 1;
    }

    .sub-heading{
        font-size: 8px;
        text-align: center;
    }

    .description{
        text-align: center;
    }

    h5{
        font-size: 6px !important;
        text-align: center;
    }

    p{
        font-size: 5px !important;
        padding: 0px !important;
        margin-bottom: 4px  !important;
    }

    img{
        height: auto;
        width: 250px;
    }

    .content-section .content{
        overflow: hidden;
    }

    .form-content{
        width: auto;

        input{
            font-size: 5px;
            padding: 10px 6px;
        }
    }

    .MuiButton-root{
        font-size: 5px !important;
        padding: 3px 5px;
    }

    .MuiPaper-root{

        picture{
            height: 50px;
        }

        .MuiCardContent-root{
            padding: 5px 5px;
        }
    }

    .MuiButtonGroup-root{
        margin-top: 10px;
    }

    .card-value{
        font-size: 20px;
    }

}


/* Skills */
@media (max-width: 1000px) {
    .dashboard-list .dashboard-item-wrapper .skill-list .skill-item-wrapper{
        width: 100% !important;
    }
}


/* Mobile Styling */
@media (max-width: 600px) {

    .dashboard{

        .side-nav{
            display: none;
            position: absolute !important;
            z-index: 3;
            width: 100% !important;
            height: 100%;
            background: rgba(0,0,0,0.8) !important;

            .logo{
                width: 210px !important;
            }

            .nav-list{
                margin: 0px 18px;

                .nav-item{
                    justify-content: center;
                    border: 1px solid #eee;
                    margin-bottom: 20px !important;
                }

                .nav-item.active{
                    background: transparent !important;
                }

                .logout-btn{
                    display: flex !important;
                }
            }

        }

        .side-nav.opened{
            display: block;
        }

        .controls{
            .super-nav{
                display: none;
            }

            .menu-toggle{
                display: block !important;
            }
        }

        .dashboard-list{

            .dashboard-item{
                flex-direction: column;
                padding: 20px 0px 20px 0px;
                align-items: center;


                .video{
                    margin-right: 0px;
                    width: 90%;
                    height: 275px;
                    margin-bottom: 20px;
                }

                .cover-image-wrap{
                    width: 90%;
                    margin-bottom: 20px;
                }

                .cover-image{
                    width: 100% !important;
                    height: 240px !important;
                    margin: 0px;
                }

                .avatar{
                    /*margin: 0px 0px 15px 0px !important;*/
                }

                .info{
                    width: 86% !important;
                    margin-bottom: 15px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .value-item{
                    width: 80% !important;
                    margin: 0px 0px 15px 0px !important;
                    border-top: 1px dashed #eef2f7;
                    padding-top: 10px;
                }

                .value-item:last-child{
                    border-bottom: none;
                }



                .controls{
                    width: 80%;
                    justify-content: center;
                    position: relative;
                    display: flex;
                    margin-top: 26px;
                    right: auto;
                    bottom: auto;

                    .open-btn{
                        margin: 0px;
                        height: 35px;
                        width: 110px;
                    }
                }
            }
        }

        .assessment-container .question-wrap{
            position: relative;
            width: auto;
            padding: 74px 15px 0px 15px;
        }

    }

    .heading{
        font-size: 50px;
    }

    .sub-heading{
        font-size: 40px;
    }

    .MuiPaper-root{
        display: flex;

        picture{
            height: 120px;
            width: 120px;
        }

        .MuiCardContent-root{
            flex: 1;
        }
    }

    .pagination {
        position: relative;
        padding-bottom: 75px;
    }

    .mobile-hidden{
        display: none !important;
    }

}

// BASE END
#root{
    overflow-x: hidden;
}


.dashboard{
    position: relative;
    flex: 1 1;
    display: flex;

    .side-nav{
        position: relative;
        background: #1B2431;
        width: 250px;
        flex-shrink: 0;

        .logo{
            width: 85%;
            margin: auto;
            display: block;
            border-bottom: 1px solid #bccdff;
            padding: 10px 0px;
        }

        .nav-list{
            position: relative;
            padding: 18px;
            .nav-item{
                line-height: 50px;
                cursor: pointer;
                border-radius: 6px;
                margin-bottom: 5px;
                padding-left: 10px;
                display: flex;
                align-items: center;

                svg{
                    position: relative;
                    margin-right: 6px;
                    width: 26px;
                    fill: #ddd;
                }

                label{
                    margin: 0px;
                    color: #ddd;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .nav-item.active{
                background: #2398a7;
            }

            .nav-item:hover{
                background: #2398a7;
            }

            .logout-btn{
                display: none;
                background: #6d5983;
                border-color: #c5abe3;
            }

        }
    }

    .content-section{
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;

        .top-nav{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;

            .dashboard-title{
                position: relative;
                display: flex;
                align-items: center;

                svg{
                    position: relative;
                    margin-right: 6px;
                    width: 35px;
                    fill: #466694;
                    height: 35px;
                }

                label{
                    margin: 0px;
                    color: #466694;
                    font-size: 18px;
                    font-weight: 400;
                    text-transform: capitalize;
                }


                .selector{
                    position: relative;
                    cursor: pointer;

                    .title-wrap{
                        display: flex;
                        align-items: center;

                        .column{
                            position: relative;
                            display: flex;
                            align-items: center;
                            margin-right: 20px;
                        }

                        .arrow{
                            fill: #99a3b1;
                            width: 32px;
                            border-right: 1px solid #eee;
                            padding-right: 14px;
                        }
                    }

                    .dd-menu{
                        position: absolute;
                        background: #ffffff;
                        min-width: 230px;
                        z-index: 2;
                        border-radius: 5px;
                        display: none;

                        .dd-item{
                            position: relative;
                            line-height: 46px;
                            padding-left: 13px;
                            border-bottom: 1px solid #f5f5f5;
                            font-size: 16px;
                        }

                        .dd-item:hover{
                            background: #eefdff;
                        }

                    }

                }

                .selector:hover .dd-menu{
                    display: block;
                }




            }

            .controls{
                position: relative;
                display: flex;

                .menu-toggle{
                    display: none;
                    position: relative;
                    z-index: 3;

                    svg{
                        position: relative;
                        fill: #466694;
                        width: 30px;
                        height: 30px;
                    }
                }

                .menu-toggle.opened svg{
                    fill: #fff;
                }

                .super-nav{
                    position: relative;

                    .toggle{
                        position: relative;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .avatar{
                            position: relative;
                            background: #466694;
                            color: #fff;
                            width: 30px;
                            line-height: 30px;
                            text-align: center;
                            border-radius: 15px;
                            font-size: 18px;
                            margin-right: 6px;
                            text-transform: capitalize;
                        }

                        .username{
                            position: relative;
                            margin-right: 10px;
                        }

                        .arrow{
                            position: relative;
                        }
                    }

                    .drop-down{
                        position: absolute;
                        background: #fff;
                        width: 200px;
                        right: 3px;
                        top: 30px;
                        border-radius: 6px;
                        overflow: hidden;
                        box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
                        z-index: 10;
                        display: none;

                        .dd-item{
                            position: relative;
                            border-bottom: 1px solid #eee;
                            line-height: 50px;
                            text-indent: 15px;
                            cursor: pointer;
                            display: flex;
                            color: #999;
                            align-items: center;

                            svg{
                                fill: #99b6df;
                                margin-left: 15px;
                                margin-right: -9px;
                            }
                        }

                        .dd-item:last-child{
                            border: none;
                        }

                        .dd-item:hover{
                            background: #eefbfd;
                        }
                    }
                }

                .super-nav:hover .drop-down{
                    display: block;
                }

            }
        }

        .content{
            position: relative;
            flex: 1;
            padding: 0px 15px 15px 15px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
    }

    .control-bar{
        position: relative;
        border-radius: 6px;
        display: flex;
        margin-bottom: 0px;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;

        .MuiFormControl-root{
            margin-right: 5px;
        }

        .search{
            position: relative;
            display: flex;
            align-items: center;
            flex: 1 1;
            height: 52px;
            border-radius: 6px;
            border: 1px solid #ccc;
            margin-top: 5px;

            .search-icon{
                position: relative;
                width: 30px;
                height: 30px;
                margin-left: 7px;
            }

            .clear-icon{
                position: relative;
                width: 24px;
                height: 24px;
                margin-right: 7px;
                cursor: pointer;
            }

            input{
                position: relative;
                border: none;
                outline: none;
                flex: 1;
                line-height: 40px;
                font-size: 16px;
                background: transparent;
            }
        }

        .select-form{
            width: 150px;
            margin-top: 5px;
            margin-left: 10px;
            text-transform: capitalize;
            height: 58px;

            label{
                background: #f2f3f8;
                font-size: 16px;
                padding: 0px 8px;
                left: -7px;
            }
        }



    }

}

.tab-bar{
    position: relative;
    display: flex;

    .tab{
        position: relative;
        cursor: pointer;
        width: 100px;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #ccc;
        text-align: center;
        line-height: 25px;
        color: #466694;
    }

    .tab:last-child{
        border: none;
    }

    .tab.active{
        font-weight: 600;
    }

    .delete-btn{
        position: absolute;
        right: 0px;
        line-height: 24px;
        width: 100px;
        text-align: center;
        cursor: pointer;
        background: #ccc;
        color: #7d7d7d;
        border-radius: 3px;
    }

    .delete-btn:hover{
        background: #ca4c4c;
        color: #fff;
    }
}

.create-btn{
    position: relative;
    background: #0db9d0;
    color: #ffffff;
    margin-left: 10px;
    width: 50px;
    margin-top: 5px;
    height: 50px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg{
        width: 30px;
        height: 30px;
    }
}

.create-btn:hover{
    background: #2498a7;
}

.dashboard-list-wrap{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
}

.assessment-container{
    position: relative;
    background: #eefcff;
    flex: 1;
    border-radius: 6px;
    border: 1px solid #ccc;
    margin-top: 8px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;

    .header-bar{
        position: absolute;
        background: rgba(0,0,0,0.8);
        top: 0px;
        left: 0px;
        width: 100%;
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        box-sizing: border-box;
        z-index: 1;
        color: #fff;

        .section{
            position: relative;

            label{
                color: #00e6ff;
                margin: 0px;
                margin-right: 4px;
            }

            .divider{
                color: #626262;
                margin: 0px 8px;
            }
        }
    }

    .question-wrap{
        position: relative;
        width: 500px;
        flex: 1;
        overflow: hidden;
        padding-top: 74px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .question{
            position: relative;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 1.6;
            font-weight: bold;
        }

        .choice-wrap{
            position: relative;
            flex: 1;
            overflow-y: auto;

            .choice-item{
                position: relative;
                display: flex;
                background: #fff;
                border: 1px solid #ccc;
                align-items: center;
                justify-content: space-between;
                padding-left: 15px;
                border-radius: 6px;
                margin-bottom: 12px;
            }

            .choice-item.correct{
                border-color: #1b971b;
            }

            .choice-item.incorrect{
                border-color: #ff0000;
            }

        }

        .value-wrap{
            border: 1px solid #ccc;
            background: #fff;
            border-radius: 6px;
            margin-bottom: 12px;
            display: flex;
            overflow: hidden;

            input{
                border: none;
                height: 40px;
                text-indent: 20px;
                flex: 1;
                outline: none;
            }

            .answer{
                background: #82acd5;
                line-height: 34px;
                height: 34px;
                margin-top: 4px;
                margin-right: 8px;
                padding: 0px 15px;
                border-radius: 6px;
                color: #fff;
                display: none;
            }
        }

        .value-wrap.correct{
            border-color: #1b971b;
        }

        .value-wrap.incorrect{
            border-color: #ff0000;
            .answer{
                display: block;
            }
        }


        .control-wrap{
            position: relative;
            text-align: center;
            margin-top: 30px;
        }

    }

    .rationale{
        position: relative;
        background: #57ebaa;
        padding: 15px 15px;
        color: #434f47;
        border-radius: 5px;
    }

    .result-screen{
        position: relative;
        width: 500px;
        background: #fff;
        border: 1px solid;
        padding: 30px 0px;
        border-radius: 10px;
        box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.2);

        h3{
            position: relative;
            margin: 0px;
            font-size: 18px;
            text-align: center;
            color: #adb8c3;
            margin-bottom: 18px;
        }

        .score{
            text-align: center;
            position: relative;
            color: #526d89;
            font-size: 92px;
        }

        .result-list{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .result-item{
                position: relative;
                width: 100px;
                text-align: center;
                background: #fff;
                padding: 8px 0px;
                border-radius: 6px;
                margin: 0px 14px;

                label{
                    margin: 0px;
                    border-bottom: 3px solid;
                    width: 100%;
                    padding-bottom: 7px;
                    margin-bottom: 6px;
                }

                .red{
                    color: #ff0000;
                }

                .green{
                    color: #1b971b;
                }

                .value{
                    font-size: 20px;
                }
            }
        }

        .control-wrap{
            position: relative;
            text-align: center;
            margin-top: 30px;

            .btn{
                background: #526d89;
                width: 130px;
            }
        }

    }

    .video-capture{
        background: #9abaca;
        position: absolute;
        bottom: 15px;
        right: 15px;
        width: 100px;
        height: 100px;
    }

    .camera-error{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: rgba(0,0,0,0.9);
        padding-bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        .error-msg{
            margin-top: 60px;
            font-size: 18px;
            color: #35d9d1;
            display: flex;
            flex-direction: column;
            align-items: center;

            svg{
                fill: #fff;
                width: 100px;
                height: 100px;
            }
        }
    }

}


.dashboard-list{
    position: relative;
    background: #fff;
    flex: 1;
    border-radius: 6px;
    overflow-y: auto;
    border: 1px solid #ccc;
    margin-top: 8px;
    padding-bottom: 60px;
    overflow-x: hidden;

    .week-item{
        position: relative;

        .week-title{
            background: #dafbff;
            line-height: 50px;
            text-indent: 20px;
            font-size: 18px;
        }

        .lesson_list{
           position: relative;
        }
    }

    .rbc-calendar{
        margin: 15px 15px 0px 15px;
    }

    .create-btn{
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 2;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.6);
    }

    .payment-total-box{
        background: #edf6f7;
        position: fixed;
        bottom: 32px;
        left: 293px;
        font-size: 20px;
        width: 110px;
        text-align: right;
        padding: 8px 15px;
        border-radius: 6px;
        border: 1px solid #89b8bf;

        label{
            background: #edf6f7;
            margin: 0px;
            font-size: 14px;
            position: absolute;
            left: 12px;
            top: -9px;
            font-weight: bold;
        }
    }

    .control-bar{
        padding: 15px 15px 15px 15px;
        margin-bottom: 5px;
        border-bottom: 1px solid #eee;
        height: auto;
        background: linear-gradient(180deg, #f0fdff, transparent);

        .select-form{
            margin-left: 0px;

            label{
                background: #fff;
            }
        }

        .create-btn{
            position: relative;
            bottom: auto;
            right: auto;
        }
    }

    .dashboard-item-wrapper{
        position: relative;
        border-bottom: 1px solid #eee;

        .dashboard-item{
            border: none;
        }

        .skill-list{
            background: #e4fffd;
            position: relative;
            padding: 24px 32px;
            background: linear-gradient(0deg, #d8f7ff, #fff);

            .skill-item-wrapper{
                position: relative;
                width: 50%;
                display: inline-block;

                .skill-item{
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    padding: 4px 15px;
                    align-items: center;

                    .left-section{
                        position: relative;
                        flex: 1;
                        display: flex;
                        align-items: center;

                        .video{
                            position: relative;
                            width: 100px;
                            height: 55px;
                            margin-right: 12px;
                            flex-shrink: 0;

                            .video-overlay{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                z-index: 5;
                                background: linear-gradient(45deg, #086c867d, transparent);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            video{
                                position: relative;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .name{
                            position: relative;
                        }

                    }

                    .controls{
                        position: relative;

                        svg{
                            cursor: pointer;
                            margin-left: 8px;
                            fill: #646c9a;
                        }

                        svg:hover{
                            fill: #969eca;
                        }

                    }

                }

            }

        }

    }


    .dashboard-item{
        position: relative;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #eee;
        padding: 15px 15px;

        .item-type{
            background: #eee;
            position: absolute;
            right: 15px;
            top: 15px;
            padding: 2px 10px;
            border-radius: 15px;
            color: #b9b9b9;
            font-size: 12px;
        }

        .cover-image-wrap{
            position: relative;
        }

        .cover-image{
            position: relative;
            background: #d8dee6;
            width: 300px;
            height: 190px;
            object-fit: cover;
            overflow: hidden;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .title-bar{
            background: #7178a2;
            color: #fff;
            display: flex;
            padding: 8px 15px;
            justify-content: space-between;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-top: -6px;

            .item{
                display: flex;
                align-items: center;
            }
        }

        .video{
            position: relative;
            background: #EEE;
            width: 300px;
            height: 190px;
            margin-right: 30px;
            flex-shrink: 0;



            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .poster{
                background: linear-gradient(45deg, black, #1f9693);
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.8;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 20px;
                flex-direction: column;

                svg{
                    width: 28px;
                    height: auto;
                }

            }
        }

        .star-btn{
            position: relative;
            margin-right: 8px;
            cursor: pointer;
            color: #eee;
        }

        .star-btn.active{
            color: #ffb200;
        }

        .sq-img{
            width: 80px;
            height: auto;
            margin: 15px 15px;
        }

        .avatar{
            position: relative;
            background: #466694;
            background: #d8dee6;
            color: #fff;
            width: 50px;
            height: 50px;
            text-align: center;
            border-radius: 25px;
            font-size: 24px;
            margin-right: 6px;
            text-transform: capitalize;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            svg{
                fill: #6f77a1;
            }
        }

        .info{
            position: relative;
            width: 350px;
            line-height: 50px;

            .name{
                position: relative;
                font-size: 20px;
                font-weight: 400;
                margin-bottom: -10px !important;
                text-transform: capitalize;
                display: flex;
                align-items: center;

                .application-btn{
                    position: relative;
                    fill: #ccc;
                    width: 16px;
                }

                .application-btn.active{
                    fill: #25b9e0;
                    cursor: pointer;
                }

            }

            .contact-info{
                position: relative;
                display: flex;
                height: 20px;

                .contact{
                    position: relative;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;

                    svg{
                        position: relative;
                        fill: #d6d6d6;
                        width: 16px;
                        margin-right: 6px;
                    }

                    label{
                        margin: 0px;
                        color: #5d5d5d;
                    }
                }
            }

            .control-wrapper{

                .controls{
                    left: 15px;

                    .right{
                        flex: 1;
                        justify-content: right;
                        display: flex;
                    }

                }

            }

        }

        .value-item{
            position: relative;
            width: 150px;
            margin-left: 15px;

            .address{
                position: relative;

                .line{
                    position: relative;
                }
            }

            img{
                width: 100px;
            }

            .new-comment-count{
                background: #2498a7;
                position: absolute;
                color: #fff;
                width: 20px;
                line-height: 22px;
                text-align: center;
                font-size: 12px;
                border-radius: 6px;
                top: -1px;
                left: 82px;
                margin: 0px;
            }

            .progress-bar{
                position: relative;
                background: #e8e8e8;
                height: 10px;
                margin: 8px 0px;

                .progress{
                    background: #2498a7;
                    height: 100%;
                    width: 25%;
                }
            }

            label{
                margin: 0px;
                color: #a2a2a2;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .btn{
                    height: 14px;
                    width: auto;
                    vertical-align: top;
                    cursor: pointer;
                    border-radius: 3px;
                    background: #dddddd;
                    padding: 0px 5px;
                    fill: #fff;
                }

                .btn:hover{
                    background: #0ab8d0;
                }

            }

            .value{
                position: relative;
                font-size: 16px;
                text-transform: capitalize;

                .inline-list{
                    flex: 1;

                    .inline-list-item{
                        border-left: 2px solid #0ee9e9;
                        padding-left: 10px;
                        margin: 10px 5px;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .controls-wrap{
                            position: relative;
                            display: flex;

                            .amount{
                                margin-left: 10px;
                            }

                            svg{
                                fill: #e7ebe7;
                                width: 22px;
                                cursor: pointer;
                                display: none;
                                margin-left: 5px;
                            }

                            svg:hover{
                                fill: #0db9d0;
                            }
                        }
                    }

                    .inline-list-item.disabled{
                        border-color: #ebebeb;
                    }

                    .inline-list-item:hover .controls-wrap svg{
                        display: block;
                    }

                    .summary-item{
                        position: relative;
                        background: #defbfb;
                        padding: 10px 10px;
                        text-align: right;
                        border-radius: 8px;
                        color: #1dabaa;
                    }

                }
            }

            .status{
                position: relative;
                background: #40d669;
                color: #fff;
                padding: 0px 5px;
                border-radius: 10px;
                font-size: 10px;
                line-height: 18px;
            }

             .day-list{
                display: flex;
                justify-content: space-between;

                .day{
                    background: #eee;
                    line-height: 19px;
                    text-align: center;
                    border-radius: 5px;
                    padding: 4px 4px;
                    margin-right: 5px;
                    width: 50px;
                }

                .day.active{
                    color: #fff;
                    background: #9dc0d6;
                }
            }

            .link-btn{
                color: #fff;
                text-align: center;
                line-height: 28px;
                border-radius: 4px;
                background: #b2d2d7;
                cursor: pointer;
                display: inline-block;
                padding: 0px 26px;
            }

            .link-btn:hover{
                background: #81c3ce;
            }

        }

        .status-item{
            width: 100px;
        }

        .age-item{
            color: #adadad;
        }

        .controls{
            position: absolute;
            right: 15px;
            bottom: 15px;
            display: flex;

            .call-btn{
                position: relative;
                margin-right: 15px;
            }

            .badge-btn{
                position: relative;
                .badge{
                    position: absolute;
                    background: #0db9d0;
                    color: #fff;
                    z-index: 2;
                    padding: 4px 6px;
                    top: -9px;
                    right: -8px;

                    font-size: 10px;
                    border-radius: 3px;
                    line-height: 13px;
                    width: 8px;
                    text-align: center;
                }
            }

            .email-btn{
                margin-left: 20px;
            }

            .student-btn{
                margin-left: 8px;
            }

            .calendar-btn{
                margin-left: 8px;
            }

            .edit-btn{
                margin-left: 14px;
            }

            .delete-btn{
                margin-left: 8px;
            }

            svg{
                position: relative;
                width: 1em;
                height: 1.2em;
                cursor: pointer;
                fill: #c5c5c5;
            }

            svg:hover{
                fill: #6784af;
            }

            .open-btn{
                position: relative;
                background: #16b5ca;
                margin-left: 10px;
                line-height: 30px;
                width: 80px;
                text-align: center;
                border-radius: 5px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;

                .badge{
                    position: absolute;
                    background: #2498a7;
                    top: -9px;
                    right: -8px;
                    border-radius: 6px;
                }

                input[type="file"]{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
            }

            .open-btn:hover{
                background: #0c9aad;
            }

            .open-btn.disabled{
                background: #bfbfbf;
                cursor: not-allowed;
            }

            .lesson-open-btn{
                background: #2498a7;
                margin-left: 10px;
                line-height: 30px;
                width: 80px;
                text-align: center;
                border-radius: 18px;
                color: #fff;
                cursor: pointer;

                svg{
                    width: 15px;
                    fill: #fff;
                }
            }

            .lesson-open-btn:hover{
                background: #087280;
            }

            .register-btn{
                background: #2498a7;
                margin-left: 10px;
                line-height: 42px;
                width: 130px;
                text-align: center;
                border-radius: 5px;
                color: #fff;
                font-size: 15px;
            }

            .register-btn:hover{
                background: #107a88;
            }

            .register-btn.active{
                background: #3eb96b;
            }

            .register-btn.active:hover{
                background: #0e8238;
            }

            .grade{
                background: #edf3f3;
                width: 81px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                font-size: 18px;
                color: #17b5ca;
            }

        }
    }

    .dashboard-item.active{
        background: #f5f9ff;
    }

    .dashboard-item.centered{
        align-items: center;
    }

}

.record-count{
    background: #f6f8ff;
    text-align: center;
    bottom: 0px;
    width: 100%;
    line-height: 32px;
}

.pagination{
    position: relative;

    .MuiPagination-ul{
        justify-content: center;
        flex: 1;
        padding-top: 15px;
    }
}

/* Modal */
div[role="presentation"]{
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-modal{
    background: #fff;
    width: 400px;
    border-radius: 6px;
    overflow: hidden;
    outline: none;

    .tab-view{
        background: #f0f2fd;
        height: 500px;
        display: flex;

        .tab-nav-list{
            background: #646c9b;
            width: 150px;

            .tab-nav-item{
                background: #646c9b;
                line-height: 44px;
                border-bottom: 1px solid #8994d8;
                color: #fff;
                padding: 0px 14px;
                cursor: pointer;
            }

            .tab-nav-item:hover{
                background: #6d77ae;
            }

            .tab-nav-item.active{
                background: #6d77ae;
            }

        }

        .tab-content{
            flex: 1;
            overflow-y: auto;
            position: relative;

            .table{
                position: relative;

                .row{
                    border-bottom: 1px solid #daddf1;
                    line-height: 44px;
                    padding: 0px 12px;
                    cursor: pointer;

                    .left{
                        position: relative;
                        display: flex;

                        img{
                            height: 50px;
                            margin-right: 10px;
                        }

                        .cell{
                            margin-right: 8px;
                            display: flex;
                            align-items: center;
                        }

                        .video-capture{
                            background: #cdd1e6;
                            width: 50px;
                            height: 50px;
                            margin-right: 12px;

                            video{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .right{
                        flex: 1;
                        display: flex;
                        justify-content: right;

                        .cell{
                            display: flex;
                            padding: 0px 10px;
                            margin-left: 8px;
                            align-items: center;

                            svg{
                                width: 16px;
                                margin-right: 3px;
                                fill: #646c9a;
                            }

                            svg:hover{
                                fill: #4dc5d5;
                            }

                        }
                    }

                    .summary{
                        position: relative;
                        display: flex;
                    }

                    .details{
                        background: #d7ddff;
                        border-radius: 8px;
                        margin-bottom: 15px;
                        line-height: 36px;
                        overflow: hidden;
                        display: none;

                        .row{
                            display: flex;
                            border-bottom: 1px solid #c6cbed;
                        }

                        .row:hover{
                            background: inherit;
                        }

                        .row:last-child{
                            border-bottom: none;
                        }

                    }

                    .details.open{
                        display: block;
                    }

                }

                .row:hover{
                    background: #eaecfb;
                }

            }


            .form{
                flex: 1;
                padding: 30px 30px;
                position: relative;


                .input-row{
                    position: relative;
                }

                .file-upload{
                    display: flex;
                    flex-direction: column;
                    height: 56px;
                    justify-content: center;
                    position: relative;
                    border: 1px solid #aaa;
                    border-radius: 4px;
                    padding: 0px 13px;

                    label{
                        position: absolute;
                        top: -10px;
                        font-size: 11px;
                        background: #fff;
                        color: #555;
                    }

                    input{
                        position: relative;
                    }

                }

                .control-group{
                    position: relative;
                    margin-top: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .cancel-btn{
                        background: #ccc;
                    }

                    .cancel-btn:hover{
                        background: #aaa;
                    }

                    .save-btn{
                        position: relative;
                        width: 45%;
                        margin: 0px 6px;
                    }

                }

            }


            .add-btn{
                background: #646c9b;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 46px;
                right: 46px;
                border-radius: 28px;
                cursor: pointer;

                svg{
                    fill: #fff;
                    width: 38px;
                    height: 32px;
                }
            }

            .add-btn:hover{
                background: #757dac;
            }

        }

    }

    .item-list{
        background: #f6f8ff;
        height: 300px;
        margin: 0px 15px 15px 15px;
        border: 1px solid #d6d8e1;
        border-radius: 6px;
        overflow-y: auto;

        .item{
            position: relative;
            display: flex;
            height: 50px;
            border-bottom: 1px solid #ddd;
            align-items: center;
            padding: 0px 15px;

            .value{
                border-right: 1px solid #d1d1d1;
                margin-right: 10px;
                padding-right: 10px;
            }

            .value:first-child{
                flex: 1;
                border: none;
                font-weight: bold;
            }

            .value:last-child{
                margin: 0px;
                padding: 0px;
                border: none;
            }
        }

        .item:last-child{
            border: none;
        }
    }

    .selection-list{
        position: relative;
        padding: 0px 10px 18px 10px;

        .selection-item{
            position: relative;
            border: 1px solid #ccc;
            margin-bottom: 10px;
            padding: 15px 15px;
            border-radius: 6px;
            cursor: pointer;
            display: block;
            color: #646c9a;
        }

        .selection-item:hover{
            background: #eef0ff;
        }

    }

    .day-selector{
        position: relative;
        margin-bottom: 20px;

        label{
            position: relative;
        }

        .day-list{
            position: relative;
            display: flex;

            .day{
                position: relative;
                cursor: pointer;
                line-height: 30px;
                background: #eee;
                flex: 1;
                text-align: center;
                margin-right: 2px;
            }

            .day:hover{
                background: #e3e3e3;
            }

            .active{
                background: #aee8ef !important;
            }
        }
    }

    textarea{
        background: #f0f2ff;
        outline: none;
        width: 100%;
        height: 100px;

        border-radius: 4px;
        padding: 15px;
        box-sizing: border-box;
    }

    .checkbox-list{
        height: 200px;
        margin-bottom: 15px;
        overflow-y: auto;
        border: 1px solid #ccc;
        border-radius: 6px;

        .checkbox-item{
            border-bottom: 1px solid #eee;

            label{
                margin: 0;
            }
        }
    }

    .StripeElement{
        padding: 16px 15px;
        border: 1px solid #c9ceed;
        border-radius: 6px;
        margin-bottom: 15px;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink{
        background: #fff;
    }

    .react-codemirror2{
        height: 200px;
        display: flex;

        div[role="presentation"] {
            display: block;
        }

        .CodeMirror{
            flex: 1 1;
            height: auto;
            margin: 5px 0px;
            border-radius: 5px;
        }
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
        color: #646c9a;
    }

    .react-calendar{
        background: #f7f7f7;
        margin: auto;
        width: 374px;
        margin-bottom: 15px;
        border-radius: 6px;
        border: none;

        .react-calendar__month-view__days__day--weekend {
            color: #5963c1;
        }

        .react-calendar__tile{
            margin-bottom: 4px;
        }

        .react-calendar__tile--now {
            background: transparent;
        }

        .lesson-date{
            background: #dfe3fd;
            border-radius: 5px;
        }

    }

    .days-of-week{
        position: relative;
        margin-bottom: 24px;

        label{
            margin: 0px;
        }

        .day-list{
            display: flex;
            justify-content: space-between;

            .day{
                background: #eee;
                width: 50px;
                line-height: 50px;
                text-align: center;
                cursor: pointer;
                border-radius: 5px;
            }

            .day:hover{
                background: #d4dafb;
            }

            .day.active{
                color: #fff;
                background: #9dc0d6;
            }
        }
    }

    .input-row{
        position: relative;
        display: flex;
        margin-bottom: 10px;

        .MuiFormControl-root:first-child{
            margin-right: 5px;
        }
    }

    .address-line{
        position: relative;
        display: flex;
        margin-bottom: 10px;

        .city{
            position: relative;
            margin-right: 5px;
        }

        .state{
            position: relative;
            margin-right: 5px;
        }

        .zip{
            position: relative;
        }
    }

   .header{
        position: relative;
        padding: 12px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

      .title{
         display: flex;
         align-items: center;

         svg{
            width: 1.4em;
            height: 1.4em;
            margin-right: 4px;
         }

         label{
            margin: 0px;
            font-size: 18px;
         }
      }

      .select-form{
         position: relative;
         text-transform: capitalize;

         .MuiOutlinedInput-root {
            height: 40px;
            width: 150px;
        }

      }

      .amount{
         font-size: 16px;
         padding-right: 6px;
      }



   }

   .form{
       position: relative;
       padding: 0px 10px;

       .MuiFormControl-root{
         display: block;
         margin-bottom: 12px;
         flex: 1;

         .MuiOutlinedInput-root {
             width: 100%;
          }

       }

        .file-field{
            position: relative;

            display: flex;
            flex-direction: column;
            border: 1px solid #ccc;
            padding: 16px 10px;
            margin-bottom: 10px;
            border-radius: 4px;

            label{
                position: absolute;
                top: -9px;
                left: 10px;
                color: #858484;
                background: #fff;
                font-size: 12px;
            }

            .name{
                position: relative;
                width: 358px;
                white-space: nowrap;
                overflow: hidden;
                font-size: 16px;
                color: #000;
            }

            input[type='file']{
                background: #ececec;
                margin-top: 10px;
                padding: 7px 6px;
                border-radius: 4px;
                color: #6c899c;
            }

        }
        .date-time-picker{
            display: flex;
            height: 60px;
            border: 1px solid #ccc;
            padding: 0px 12px;
            border-radius: 4px;

            .MuiFormLabel-root{
                background: #fff;
                top: -7px;
                left: 10px;
            }
        }

       .select-form{
         position: relative;
         text-transform: capitalize;

         label{
            background: #fff;
            padding: 0px 5px;
            left: -3px;
         }
       }

       .follow-up{
           display: flex;
           align-items: center;
           justify-content: center;
           margin-bottom: 12px;

           .MuiFormControl-root{
              margin-bottom: 0px;
           }
       }

       .textarea-control{
          position: relative;
          margin-bottom: 10px;

          label{
            position: absolute;
            z-index: 2;
            top: -8px;
            left: 14px;
            background: #fff;
            color: #888787;
          }

          textarea{
            position: relative;
            width: 100%;
            height: 100px;
            background: #fff;
            border-color: #ccc;
          }
       }
   }

   .save-btn{
        background: #646c9a;
        color: #fff;
        cursor: pointer;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        margin: 0px 10px 10px 10px;
        border-radius: 6px;

        .loader{
            position: relative;
            display: inline-block;
            height: 25px;
            width: 60px;
            padding: 8px;
            margin-left: -30px;
        }
   }

   .save-btn:hover{
        background: #48528a;
   }
}


.list-modal{
    background: #fff;
    width: 450px;
    border-radius: 6px;

    .title{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 0px 0px 0px;

        .avatar{
            background: #466694;
            color: #fff;
            width: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 25px;
            font-size: 22px;
            margin-right: 6px;
            text-transform: capitalize;
            margin-bottom: 5px;
        }

        label{
            margin: 0px;
            font-size: 18px;
            text-transform: capitalize;
        }

    }

    .item-list{
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;

        .item{
            position: relative;
            background: #f2fafb;
            margin: 0px 10px 5px 10px;
            border-radius: 6px;
            padding: 15px 15px;
            display: flex;
            align-items: center;

            .header{
                position: relative;
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .user{
                    position: relative;
                    display: flex;
                    align-items: center;

                    .avatar{
                        position: relative;
                        background: #466694;
                        color: #fff;
                        width: 20px;
                        line-height: 20px;
                        text-align: center;
                        border-radius: 10px;
                        font-size: 14px;
                        margin-right: 6px;
                        text-transform: capitalize;
                    }

                    label{
                        margin: 0px;
                        text-transform: capitalize;
                    }
                }

                .date{
                    position: relative;
                }
            }

            .note{
                padding: 10px 5px 0px 5px;
            }

            .item-value{
                position: relative;
                margin-right: 50px;

                label{
                    margin: 0px;
                    font-weight: 500;
                }

                .value{
                    position: relative;
                }
            }

            .item-value:last-child{
                background: #ff0000;
            }


            .controls{
                background: #00ff00;

                flex: 1;
                display: flex;
                justify-content: flex-end;

                .delete-btn{
                    fill: #ccc;
                    cursor: pointer;
                    opacity: 0;
                }

                .delete-btn:hover{
                    fill: #646c9a;
                }
            }

        }

        .item:hover .controls .delete-btn{
            opacity: 1;
        }

        .note-item{
            flex-direction: column;
            align-items: flex-start;
        }
    }

    textarea{
        background: #f9f9f9;
        width: 90% !important;
        margin: auto;
        display: block;
        border: 1px solid #dadada;
        padding: 10px 10px;
        margin-bottom: 5px;
        border-radius: 6px;
        resize: none;
        height: 80px;
        outline: none;
    }

    .form{
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 5px 11px 10px 11px;

        .MuiFormControl-root{
            width: 154px;
        }
    }

    .save-btn{
        background: #646c9a;
        color: #fff;
        cursor: pointer;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        margin: 0px 10px 10px 10px;
        border-radius: 6px;
   }

   .save-btn:hover{
        background: #48528a;
   }

}


#ad-campaign-modal{
    width: 800px;
}

#card-element{
    padding: 14px 8px;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin-bottom: 16px;
}

.rbc-event{
    padding: 14px 10px;
    background: linear-gradient(45deg, #2498a7, #351877);
    border-radius: 6px;

    .lesson-event{
        position: relative;
        display: flex;
        justify-content: space-between;
        color: #fff;

        svg{
            fill: #fff;
            height: 16px;
        }
    }
}


.slide-editor-wrap{
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .mde-preview .mde-preview-content{
        h1{
            font-size: 1.6em;
            color: #466694;
            padding-bottom: 0.6rem;
            border-bottom: 1px solid #8895a7;
        }

        h2{
            color: #5c81b5;
        }

        p{
            color: #466694;
            font-size: 14px;
        }

        code{
            background: #e8e8e8;
            color: #26a5b5;
        }

        pre{
            background: #e8e8e8;
            border: 1px solid #ccc;
            padding: 10px 10px;
            code{
                line-height: 20px;
            }
        }
    }

    .new-slide-btn{
        background: #a2b9da;
        position: absolute;
        right: 15px;
        top: -30px;
        color: #fff;
        width: 100px;
        line-height: 40px;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
    }

    .new-slide-btn:hover{
        background: #7796c1;
    }

    .slide-editor{
        position: relative;
        background: #fff;
        flex: 1;
        margin-top: 15px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        display: flex;

        .slide-list-wrap{
            position: relative;
            position: relative;
            width: 250px;
            display: flex;
            flex-direction: column;
            padding: 6px 6px;

            .slide-list{
                position: relative;
                flex: 1;

                .slide{
                    position: relative;
                    background: #eee;
                    margin-bottom: 4px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0px 10px;
                    cursor: pointer;

                    label{
                        margin: 0px;
                    }

                    .delete-btn{
                        fill: #cecece;
                        opacity: 0;
                    }

                    .delete-btn:hover{
                        fill: #c37474;
                    }
                }

                .slide:hover{
                    position: relative;

                    .delete-btn{
                        opacity: 1;
                    }
                }

                .slide.active{
                    background: #eaf0f9;
                }
            }

            .new-btn{
                background: #a2b9da;
                color: #fff;
                line-height: 50px;
                border-radius: 6px;
                text-align: center;
                cursor: pointer;
                font-size: 14px;
            }

            .new-btn:hover{
                position: relative;
                background: #7796c1;
            }

        }

        .column{
            position: relative;
            background: linear-gradient(180deg, #eee, transparent);
            flex: 1;
            margin: 2px;
            display: flex;
            flex-direction: column;
            width: 100px;
            overflow: scroll;

            .heading{
                position: relative;
                background: #466694;
                color: #fff;
                padding: 10px 15px;
                border-radius: 4px;
            }
        }

        div[role="presentation"] {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
        }

        .output-area{
            background: #fff;
            flex: 1;
            padding: 30px;
        }

        .tabbed-editor{
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;

            .tab-bar{
                position: relative;

                .tab{
                    position: relative;
                }

                .tab.active{
                    position: relative;
                }
            }

            .tab-content{
                position: relative;
                background: #eee;
                flex: 1;
                padding: 10px;
                display: flex;
                flex-direction: column;

                .react-codemirror2{
                    flex: 1;
                    height: 300px;

                    .CodeMirror{
                        height: 100%;
                    }
                }
            }




        }

        .value-editor{
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;

            .heading{
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .MuiSelect-select.MuiSelect-select {
                    background: #c1d2ea;
                    padding: 5px 32px 5px 10px;
                    outline: none;
                    line-height: 16px;

                    .MuiSelect-iconOutlined {
                        right: 4px;
                        top: calc(50% - 10px);
                    }

                }

                .control-group{
                    position: relative;
                    display: flex;
                    align-items: center;

                    .test-btn{
                        position: relative;
                        background: #b1bed2;
                        padding: 6px 10px;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    .test-btn:hover{
                        background: #4dbdcc;
                    }

                    .add-btn{
                        position: relative;
                        fill: #b1bed2;
                        margin-left: 12px;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }

                    .add-btn:hover{
                        fill: #4dbdcc;
                    }

                }
            }

            .run-btn{
                background: #2498a7;
                position: absolute;
                color: #fff;
                padding: 10px 36px;
                bottom: 14px;
                right: 10px;
                z-index: 2;
                border-radius: 5px;
                font-size: 14px;
                cursor: pointer;

                span{
                    position: absolute;
                    left: 9px;
                    top: 10px;
                }

                label{
                    margin: 0px;
                }
            }

            .run-btn:hover{
                background: #198896;
            }

            textarea{
                position: relative;
                flex: 1 1;
                padding: 20px;
                border: none;
                background: transparent;
                outline: none;
                resize: none;
                min-height: 400px !important;
            }

            .controls{
                position: relative;
                text-align: center;
            }

            .react-mde{
                flex: 1;
            }

            .react-mde * {
                background-color: inherit;
            }

            .react-codemirror2{
                height: 100%;
                display: flex;

                div[role="presentation"] {
                    display: block;
                }

                .CodeMirror{
                    flex: 1 1;
                    height: auto;
                    margin: 5px 0px;
                    border-radius: 5px;
                }
            }

            .test-list{
                position: relative;
                flex: 1;

                .col-names{
                    position: relative;
                    padding: 0px 15px;
                    background: #eee;
                    color: #aaa;
                    margin-top: 3px;
                    border-radius: 3px;

                    label{
                        margin: 0px;
                        width: 150px;
                    }
                }

                .test-item{
                    position: relative;
                    display: flex;
                    border-bottom: 1px solid #eee;
                    padding: 0px 15px;

                    .value{
                        position: relative;
                        width: 150px;
                        line-height: 36px;
                    }

                    .value.green{
                        color: #3c9a3c;
                    }

                    .value.red{
                        color: #ff0000;
                    }

                    .description{
                        line-height: 36px;
                        flex: 1;
                    }

                    .control-group{
                        position: relative;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        svg{
                            margin-left: 5px;
                            cursor: pointer;
                            fill: #ccc;
                        }

                        svg:hover{
                            fill: #466694;
                        }

                    }
                }

                .test-item:last-child{
                    border: none;
                }
            }

        }

    }

    .control-bar{
        position : relative;
        justify-content: center;

    }

}


.student-lesson-view{
    position: relative;

    .comment-btn{
        position: absolute;
        background: #9c9c9c;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        box-shadow: 0px 0px 9px rgba(0,0,0,0.6);
        z-index: 2;
        right: 0px;
        bottom: 62px;
        cursor: pointer;

        svg{
            position: relative;
            fill: #fff;
        }
    }

    .comment-btn:hover{
        background: #519da7;
    }

    .comment-btn.active{
        background: #519da7;
    }

    .comment-box{
        position: absolute;
        bottom: 50px;
        right: 25px;
        padding: 20px 20px;
        z-index: 2;
        border: 5px solid #fff;
        background: #ffbf00;
        color: #7d6312;
    }

    .slide-editor{
        margin: 0px;
        background: transparent;
        box-shadow: none;

        .column{
            background: transparent;
        }

        .mde-header{
            display: none;
        }

        .react-mde {
            border: 1px solid #c8ccd0;
            border-radius: 7px;
            margin-bottom: 4px;
        }
    }

    .control-bar{
        position: relative;
        font-size: 18px;

        .nav-btn{
            position: relative;
            background: #dfe8f7;
            line-height: 40px;
            width: 80px;
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 12px;
            font-size: 16px;

            svg{
                fill: #466694;
                width: 14px;
            }
        }

        .nav-btn.disabled{
            background: #ccc !important;
            color: #848484;
            cursor: not-allowed;

            svg{
                fill: #848484;
            }
        }

        .nav-btn:hover{
            background: #bed3f5;
        }

        label{
            margin: 0px 30px;
        }

    }
}

.file-tree{
    position: relative;
    background: #6d6d6d;
    flex: 1 1;
    border-radius: 6px;
    padding: 15px;
    color: #fff;

    .file-node{
        position: relative;
        cursor: pointer;

        .name{
            position: relative;
            svg{
                fill: #2498a7;
            }

            .comment-badge{
                background: #00ff00;
                position: absolute;
                z-index: 2;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                left: -5px;
                top: 0px;
            }
        }

        .children{
            position: relative;
            padding-left: 30px;
        }

    }
}

.file-content{
    position: relative;
    background: #253239;
    flex: 1;
    border-radius: 6px;

    .react-codemirror2{
        height: 100%;
    }

    .CodeMirror{
        flex: 1 1;
        height: 100%;
        margin: 5px 0px;
        border-radius: 5px;
    }
}


.student-detail-page{
    position: relative;
    background: #fff;
    flex: 1 1;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin-top: 8px;
    padding: 40px;
    display: flex;
    flex-direction: column;

    .tab-header{
        position: relative;
        display: flex;
        align-items: center;

        .avatar{
            position: relative;
            background: #d8dee6;
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            border-radius: 50px;
            color: #fff;
            border: 4px solid #b7bcd1;
            text-transform: capitalize;
        }

        .tab-bar-wrap{
            position: relative;
            flex: 1;

            .tab-bar{
                position: relative;
                border-bottom: 1px solid #f7f7f7;
                padding-bottom: 6px;
            }

            .info-bar{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 17px;

                .name{
                    position: relative;
                    font-size: 20px;
                    text-transform: capitalize;
                }

                .info-group{
                    position: relative;
                    display: flex;

                    .info-item{
                        position: relative;
                        margin-left: 10px;

                        svg{
                            fill: #17b5ca;
                        }

                        label{
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
}



.section-item{
    position: relative;
    border-bottom: 1px solid #eee;

    .dashboard-item{
        border: none;
    }

    .variant-list{
        position: relative;
        background: linear-gradient(180deg, gainsboro, transparent);
        height: auto;
        overflow: hidden;
        width: 1398px;
        padding-bottom: 30px;

        .slide{
            position: relative;
            height: auto;
            min-height: 200px;
            background: linear-gradient(45deg, #d6d8e2, transparent);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .control-bar{
                width: calc(100% - 30px);
                bottom: -5px;
                border: none;
                border-radius: 0px;
                background: linear-gradient(0deg, rgba(255,255,255,0.8), transparent);
                z-index: 2;
                padding-left: 15px;

                svg{
                    margin-left: 10px;
                    cursor: pointer;
                    fill: #b5fff6;
                }

                svg:hover{
                    fill: #7ad6cb;
                }

                .name{
                    position: relative;
                    font-size: 18px;
                }

                .controls{
                    display: flex;
                    align-items: center;
                }

                .value-item{
                    position: relative;
                    margin-left: 30px;
                    margin-top: -11px;

                    label{
                        position: relative;
                        color: #a2a2a2;
                        margin: 0px;
                    }

                    .value{
                        position: relative;
                    }
                }



                .super-controls{
                    position: absolute;
                    top: -36px;
                    right: 18px;
                    opacity: 0.5;
                    display: none;
                }

            }


            /* Lead Form */
            .contact-slide-wrap{
                width: 100%;

                .contact-slide{

                    .title{
                        font-size: 40px;
                    }

                    .description{
                        font-size: 10px;
                    }

                    .form-container{
                        width: 100%;

                        input{
                            font-size: 13px;
                            margin: 0.4rem;
                            padding: 0.5rem 1rem;
                            width: 36%;
                        }
                    }

                    .btn{
                        font-size: 15px;
                        width: 115px;
                        line-height: 18px;
                    }
                }
            }


            /* Program Details */
            .program-details{
                padding: 12px 0px;

                .container{
                    padding: 0px 15px;

                    .two-column{
                        display: flex;

                        .column1{
                            color: #1b3c6f;

                            .title{
                                font-size: 10px;
                            }

                            .description{
                                font-size: 6px;
                            }

                            ul{
                                font-size: 6px;
                                margin-bottom: 8px;
                            }

                            a{
                                font-size: 6px;
                            }

                        }

                        .column2{
                            margin-right: auto;
                        }
                    }
                }
            }



            /* Program Modules */
            .program-modules{

                .container{
                    padding: 15px;

                    .heading{
                        font-size: 20px;
                    }

                    .description{
                        font-size: 8px;
                    }

                    .module-list{
                        margin-bottom: 10px;

                        .module-item{
                            padding: 0px 5px;

                            .name{
                                font-size: 6px;
                            }

                            .msg{
                                font-size: 6px;
                            }
                        }
                    }

                    a{
                        font-size: 6px;
                    }

                }

            }


            /* Program Price */
            .program-price{
                position: relative;

                .container{
                    padding: 0;

                    .heading{
                        font-size: 12px !important;
                    }

                    .description, .price, .pink-btn{
                        font-size: 6px !important;
                    }

                    .pink-btn, .blue-btn{
                        padding: 8px 12px !important;
                        height: 6px !important;
                        font-size: 4px !important;
                    }

                    .blue-btn{
                        margin-left: 6px !important;
                    }

                    h3{
                        font-size: 10px !important;
                    }

                    .title{
                        font-size: 8px !important;
                    }

                    .msg{
                        font-size: 8px !important;
                    }

                    .link, .pink-link, .back-to-top{
                        font-size: 8px !important;
                    }

                    .cards:nth-child(1) {
                        border-bottom: 1px solid #e8e8ec;
                        margin-bottom: 1rem;
                        padding-bottom: 1rem;
                    }

                    .price, .control-group{
                        margin-bottom: 16px !important;
                    }
                }
            }




            /* BASE SLIDE */
            .base-slide{
                position: relative;
                width: 100%;

                .slide-img{
                    width: 8vw;
                }

                .heading{
                    font-size: 1vw;
                    width: auto;
                    line-height: 1.2vw;
                    margin-bottom: 6px;
                }

                .sub-heading{
                    width: 70%;
                    font-size: .6vw;
                    margin-bottom: 8px;
                    margin-top: 8px;
                }

                p{
                    font-size: .5vw;
                    width: 70%;
                    margin-bottom: 6px;
                }

                .stat-list{
                    width: 60%;

                    .stat-item{
                        position: relative;

                        .value{
                            font-size: 2vw;
                        }

                        .name{
                            font-size: .6vw;
                            margin-top: -8px;
                        }

                        .description{
                            font-size: .17vw;
                            padding: 0px 10px;
                        }
                    }

                }

                .control-group{
                    margin-top: 12px;

                    .btn{
                        font-size: .5vw;
                        padding: 5px 5px;
                    }
                }

                .check-list{
                    width: 60%;

                    .column{
                        padding: 0px 5px;

                        .item{
                            height: 18px;
                            font-size: .2vw;

                            svg{
                                height: 10px;
                                margin-right: 4px;
                            }
                        }
                    }

                }

                .card-list{
                    position: relative;
                    width: 70%;

                    .card-item{
                        position: relative;
                        flex: 1;
                        margin: 2px;
                        width: auto;

                        .title{
                            font-size: .3vw;
                            margin: 0px 0px 3px 0px;
                        }

                        .description{
                            font-size:  .2vw;
                        }

                        .btn{
                            font-size:  .2vw;
                        }

                    }
                }

                .img-info-list{
                    width: 100%;
                    margin: auto;

                    .list-item{
                        font-size: 0.5vw;
                        margin-bottom: 20px;

                        img{
                            width: 50px;
                        }

                        .name{
                            margin-bottom: 4px;
                            font-size: 10px;
                        }

                        .description{
                            font-size: 0.5vw;
                        }

                        a{
                            font-size: 0.5vw;
                        }

                        .btn{
                            padding: 4px 10px;
                        }
                    }

                }

            }

            /* PROGRAM OVERVIEW */
            .program-overview{
                padding: 0 !important;

                .column{
                    position: relative;
                    flex: 1;
                    padding: 20px 15px;

                    .title{
                        font-size: 11px;
                        line-height: 12px;
                        margin-bottom: 4px;
                    }

                    .description{
                        font-size: 5px;
                    }

                    .feature-list{
                        font-size: 6px;

                        .feature-item{
                            margin-bottom: 2px;
                            padding-bottom: 2px;

                            .icon{
                                width: 20px;
                                height: 20px;

                                img{
                                    width: 10px;
                                }
                            }

                            .name{
                                position: relative;
                            }
                        }
                    }
                }
            }

            /* Blurb Slide */
            .blurb-slide{
                height: 135px;

                .container{
                    padding: 1rem;

                    .content{
                        overflow: visible;

                        #image1{
                            width: 3rem;
                            left: -1.5rem;
                        }

                        #text-box{
                            padding: 2rem 1rem 0.7rem;

                            .text{
                                font-size: 11px;
                                line-height: 15px;
                            }
                        }

                        #image2{
                            width: 3rem;
                            right: -1.5rem;
                        }

                        #image3{
                            bottom: 4.5rem;
                            position: absolute;
                            right: -2rem;
                            width: 3rem;
                            z-index: 11;
                        }
                    }
                }

            }

            /* Timeline Slide */
            .timeline-slide{
                padding: 20px 0px;

                .container{
                    padding: 0px;

                    .card-list{
                        width: 100%;
                        display: flex;

                        .card{

                            .icon{
                                width: 20px;
                                height: 20px;
                                margin-bottom: 8px;

                                img{
                                    width: 10px;
                                }
                            }

                            .name{
                                font-size: 10px;
                            }

                            .description{
                                font-size: 6px;
                            }
                        }

                        .arrow{
                            svg{
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                }

            }

            /* Documents */
            .documents{
                position: relative;
                padding: 30px;
                box-sizing: border-box;

                .heading, .sub-heading{
                    font-size: 20px !important;
                }

                .sub-heading{
                    font-size: 18px !important;
                    margin: 0px;
                }

                 .item-list{
                    position: relative;
                    padding-left: 5px;
                    padding-top: 5px;
                    margin-bottom: 10px !important;

                    .item{
                        display: flex;
                        align-items: center;
                        font-size: 6 !important;
                        margin-bottom: 6px;
                        color: #32a6a7;

                        svg{
                            position: relative;
                            width: 10px;
                            fill: #189b96;
                            margin-right: 6px;
                        }

                        .name{
                            position: relative;
                            font-size: 16px;
                        }
                    }
                 }

                 .container{
                    position: relative;

                    .pink-btn{
                        padding: 6px 10px;
                        margin-right: 10px;
                    }

                    .text-wrapper{
                        position: relative;

                        p{
                            position: relative;
                            white-space: nowrap;
                            font-size: 6px;
                            line-height: 4px;
                            text-align: left;
                        }
                    }
                 }

            }

            /* Sticky Side Nav */
            .sticky-side-nav{
                background: #fff;
                position: relative;
                left: 0px;
                top: 0px;
                margin-top: 15px;
            }

        }

        .slide:hover .super-controls{
            display: block;
        }

        .chevron-btn{
            background: rgba(255, 255, 255, 0.8);
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 27px;
            font-size: 20px;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
        }

        .control-group{
            position: relative;
            text-align: center;
            margin-top: 30px;

            .control-btn{
                position: relative;
                text-align: center;
                width: 200px;
                background: #7d83ab;
                display: inline-block;
                line-height: 40px;
                color: #fff;
                border-radius: 4px;
                cursor: pointer;
            }

            .control-btn:hover{
                background: #696f96;
            }
        }

    }
}




.prospect-item.inactive{
    background: #f8ffd4;
}

.prospect-item.not-interested{
    background: #ffd4d4;
}

.prospect-item.registered{
    background: #d4faff;
}




/* Student Item */
.student-item{
    position: relative;
    background: linear-gradient(180deg, #baf7ff, #e5fcff);

    .info{
        background: #fff;
        padding: 0px 15px;
        border-radius: 10px;
        min-height: 240px;
        padding-bottom: 60px;

         .mobile-tabs, .mobile-controls{
            display: none;
         }

         .name{
            color: #17b5ca;
            font-weight: bold !important;
            line-height: 1;
            padding-bottom: 8px !important;
        }

        .header{
            position: relative;
            display: flex;
            padding: 15px 0px;
            align-items: center;

            .avatar{
                background: linear-gradient(45deg, #2498a7, #85176d) !important;
                width: 66px !important;
                height: 66px !important;
                border-radius: 10px !important;
                margin-right: 9px !important;
            }

            .text-wrap{
                position: relative

                ;.contact-info{
                    margin-bottom: 0px;
                }
            }

            .is_active{
                position: absolute;
                background: #00ff00;
                top: 10px;
                right: 0px;
                line-height: 16px;
                width: 64px;
                text-align: center;
                border-radius: 15px;
                font-size: 10px;
            }

            .is_active.inactive{
                background: #ccc;
            }

        }
    }

    .contact-info{
        margin-bottom: 20px;

        .contact{
             svg{
                fill: #17b5ca !important;
            }

            label{
                margin-left: 2px !important;
            }
        }
    }

    .info-item{
        position: relative;
        line-height: 1;
        font-size: 14px;
        margin-bottom: 15px;

        label{
            margin: 0px 0px 3px 0px;
            line-height: 1;
            color: #17b5ca;
            font-weight: bold;
        }

        .value{
            position: relative;
            line-height: 1;
            color: #bbbbbb;

            .line{
                position: relative;
            }
        }

        .title-bar{
            background: #fff;
            align-items: center;
            padding: 0px 0px;

            label{
                position: relative;
                margin: 0px;
            }

            .add-btn{
                position: relative;
                fill: #d8e3e4;
                cursor: pointer;
            }

            .add-btn:hover{
                fill: #0ab8d0;
            }

        }

        .doc-list{
            position: relative;
            margin-top: 5px;

            .doc-item{
                position: relative;
                display: flex;
                height: 38px;
                align-items: center;
                border-top: 1px solid #e7e7e7;

                .name{
                    position: relative;
                    font-size: 12px;
                    flex: 1;
                    font-weight: normal !important;
                    color: #ccc;
                }

                .name.active{
                    color: #0ab8d0;
                }

                svg{
                    fill: #e3e3e3;
                    width: 16px;
                    margin-left: 5px;
                    cursor: pointer;
                    display: none;
                }

                svg:hover{
                    fill: #17b5ca;
                }

                .upload-btn{
                    position: relative;
                    cursor: pointer;

                    input{
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                    }
                }

                .upload-btn:hover svg{
                    fill: #17b5ca;
                }
            }

            .doc-item:hover svg{
                display: block;
            }


        }

        img{
            display: block;
            width: 100px;
        }

        .capitalize{
            text-transform: capitalize;
        }
    }

    .controls{
        position: absolute !important;
        right: 15px;
        bottom: 15px;
        display: none !important;
        height: 30px;

        .control-btn{
            cursor: pointer;
            fill: #e9e8e8 !important;
        }

        .control-btn:hover{
            fill: #17b5ca !important;
        }
    }

    .info:hover .controls{
        display: flex !important;
    }

    .progress-info, .module-info{
        background: #fff;
        padding: 0px 15px;
        border-radius: 10px;
        flex: 1;
        min-height: 240px;
        margin-left: 10px;

         .name{
            color: #17b5ca;
            font-weight: bold !important;
            font-size: 20px;
            line-height: 50px;
        }

        .progress-bar-container{
            position: relative;
            margin-bottom: 15px;
            border-bottom: 1px solid #ebe4e4;
            padding-bottom: 15px;


            label{
                position: relative;
                color: #b5b5b5;
                margin-bottom: 2px;
            }

            .progress-wrap{
                position: relative;
                display: flex;
                align-items: center;

                .bar{
                    position: relative;
                    background: #efefef;
                    height: 20px;
                    flex: 1;

                    .percent-complete{
                        position: absolute;
                        z-index: 2;
                        font-size: 10px;
                        top: 3px;
                        left: 8px;
                        color: #66d4e3;
                    }

                    .complete{
                        position: relative;
                        background: #0db9d0;
                        height: 100%;
                        width: 83%;
                    }
                }

                .open-btn{
                    position: relative;
                    width: 100px;
                    text-align: center;
                    background: #92a8ab;
                    color: #fff;
                    margin-left: 4px;
                    cursor: pointer;
                    line-height: 20px;
                }

                .open-btn:hover{
                    background: #51a4af;
                }
            }

        }

        .topics{
            position: relative;
            margin-bottom: 15px;
            padding-bottom: 15px;


            label{
                position: relative;
                color: #b5b5b5;
                margin-bottom: 2px;
            }

            .topic-list{
                position: relative;

                .topic-item{
                    position: relative;
                    border-left: 4px solid #0db9d0;
                    margin-bottom: 5px;
                    line-height: 26px;
                    padding: 0px 8px;
                    background: #f9f9f9;
                    display: flex;
                    justify-content: space-between;

                    .topic-name{
                        position: relative;
                    }

                    .topic-stats{
                        position: relative;
                    }
                }
            }
        }

        .stat-bar-container{
            position: relative;
            margin-bottom: 15px;
            border-bottom: 1px solid #ebe4e4;
            padding-bottom: 15px;


            label{
                position: relative;
                color: #b5b5b5;
                margin-bottom: 2px;
            }

            .stat-bar{
                position: relative;
                display: flex;
                justify-content: space-around;

                .stat{
                    position: relative;
                    text-align: center;

                    .value{
                        position: relative;
                        font-size: 26px;
                        color: #0db9d0;

                        .percent{
                            position: absolute;
                            font-size: 12px;
                            right: -9px;
                            top: 0px;
                        }
                    }

                    .label{
                        position: relative;
                        color: #b5b5b5;
                    }
                }
            }
        }
    }


    .module-list{
        margin-bottom: 30px;
        border: 1px solid #e1e1e1;
        border-radius: 5px;

        .module-item{
            border-bottom: 1px solid #e1e1e1;
            display: flex;
            align-items: center;
            padding: 15px 15px;

            .title-item{
                position: relative;
                width: 150px;

                .title{
                    font-weight: bold;
                }

                .sub-title{
                    color: #a9afcd;
                    font-weight: bold;
                }
            }


            .value-item{
                position: relative;
                display: flex;
                flex-direction: column;

                .value-name{
                    position: relative;
                    font-size: 10px;
                    color: #15b4ca;
                }

                .value{
                    position: relative;
                }

                .progress-bar{
                    height: 15px;

                    .progress{
                        position: relative;
                        font-size: 10px;
                        text-indent: 5px;
                        color: #6accda;
                    }
                }

                .section-progress-bar{
                    height: 15px;
                    display: flex;
                    margin: 8px 0px;

                    .section{
                        position: relative;
                        font-size: 10px;
                        color: #a8a8a8;
                        flex: 1;
                        text-align: center;
                        background: #e8e8e8;
                        margin-right: 1px;
                        cursor: pointer;
                    }

                    .section.active{
                        background: #2498a7;
                        color: #6accda;
                    }

                }

                .value-list{
                    position: relative;
                    display: flex;

                    .value{
                        margin-right: 16px;
                        display: flex;
                        flex-direction: column-reverse;
                        margin-top: 4px;

                        label{
                            font-size: 8px;
                            color: #d6d6d6;
                        }
                    }
                }
            }
        }

        .module-item:last-child{
            border: none;
        }

    }

    .module-info{
        position: relative;
        min-height: 325px;

        .header{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .name{
                color: #17b5ca;
                font-weight: bold !important;
                font-size: 20px;
                line-height: 50px;
            }

            .btn-group{
                position: relative;

                .add-btn{
                    position: relative;
                    fill: #ccc;
                    cursor: pointer;
                }

                .add-btn:hover{
                    fill: #17b5ca;
                }
            }
        }

        .module-item{
            position: relative;

            .btn-group{
                position: relative;
                flex: 1;
                display: flex;
                justify-content: right;
                opacity: 0;

                .btn{
                    position: relative;
                    width: 22px;
                    fill: #e7e6e6;
                    cursor: pointer;
                }

                .btn:hover{
                    fill: #66cad7;
                }
            }
        }

        .module-item:hover .btn-group{
            opacity: 1;
        }

    }

    .payment-info{
        background: #fff;
        padding: 0px 15px;
        border-radius: 10px;
        width: 370px;
        min-height: 240px;
        margin-left: 10px;

        .header{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .name{
                color: #17b5ca;
                font-weight: bold !important;
                font-size: 20px;
                line-height: 50px;
            }

            .btn-group{
                position: relative;

                .add-btn{
                    position: relative;
                    fill: #ccc;
                    cursor: pointer;
                }

                .add-btn:hover{
                    fill: #17b5ca;
                }
            }
        }

        .payment-list{
            position: relative;

            .payment-item{
                position: relative;
                border-radius: 5px;
                border: 1px solid #e1e1e1;
                height: 50px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                .value-list{
                    position: relative;
                    display: flex;
                    flex: 1;
                    color: #8b8c8d;

                    .value-item{
                        position: relative;
                        width: auto;
                        border-right: 1px solid #efeded;
                        padding-right: 15px;
                    }

                    .value-item:last-child{
                        border-right: none;
                        padding-right: 15px;
                    }

                    .title{
                        font-weight: bold;
                        color: #66cad7;
                    }
                }

                .btn-group{
                    position: relative;
                    align-items: center;
                    padding-right: 15px;
                    display: none;

                    .btn{
                        position: relative;
                        width: 22px;
                        fill: #e7e6e6;
                        cursor: pointer;
                    }

                    .btn:hover{
                        fill: #66cad7;
                    }
                }
            }

            .payment-item:hover .btn-group{
                display: flex;
            }

            .payment-item.active{
                border-color: #0db9d0;
            }

        }

        .total-bar{
            background: #f7f7f7;
            display: flex;
            justify-content: end;
            align-items: center;
            height: 46px;
            margin-bottom: 20px;

            label{
                position: relative;
                margin: 0px 0px 0px 0px;
                color: #0db9d0;
                font-weight: bold;
            }

            .value{
                position: relative;
                margin-left: 8px;
                margin-right: 10px;
            }

        }

    }

}


@media (max-width: 600px) {

    .student-item{
        position: relative;

        .progress-info, .payment-info{
            display: none;
        }

        .info:hover .controls{
            display: none !important;
        }

        .info{
            padding-bottom: 18px !important;

            .mobile-tabs{
                display: block;
                position: absolute;
                right: 15px;
                top: 4px;
                z-index: 3;

                svg{
                    fill: #ccc;
                    margin-left: 6px;
                }

                .active{
                    fill: #5ac5d3;
                }
            }

            .mobile-controls{
                position: relative;
                display: flex;
                justify-content: center;

                a{
                    position: relative;
                }

                svg{
                    position: relative;
                    fill: #e3e3e3;
                    margin: 0px 8px;
                    width: 22px;
                    vertical-align: top;
                }
            }

        }

    }

}



.chapter-reader{
    position: relative;
    background: #eeeeee;
    flex: 1;
    overflow: hidden;

    .reader-control-bar{
        position: relative;
        background: #3b4d68;
        color: #7fccec;
        padding: 10px 15px;
        display: flex;
    }

    .react-pdf-wrap{
        position: relative;
        flex: 1;
        height: 100%;
        overflow: scroll;

        .react-pdf__Document{
            position: relative;
            background: #ebebeb;
            padding: 50px 0px;
        }
    }



    canvas{
        width: 80% !important;
        height: auto !important;
        margin: auto !important;
    }

    .debug-feedback{
        position: absolute;
        z-index: 3;
        background: #3b4d68;
        color: #7fccec;
        padding: 10px 15px;
        display: flex;
        width: 100%;
        bottom: 0px;
    }
}


.program-item{
    .cover-image-wrap{
        opacity: 0.8;
    }
}

.program-item:hover{
    .cover-image-wrap{
        opacity: 1;
    }
}


.video-test{
    position: relative;

    .video-wrapper{
        background: #cfcad5;
        display: flex;
        padding: 40px;

        video{
            background: #ccc;
            object-fit: cover;
            width: auto;
        }

    }

    .control-wrap{
        display: flex;
        justify-content: center;
        margin: 15px;
    }

}


@media (max-width: 600px){
    .video-test{
       position: relative;

        .video-wrapper{
            position: relative;
            flex-direction: column;

            video{
                height: 200px;
                width: 200px;
                margin: auto !important;
                margin-bottom: 40px !important;
            }
        }
    }
}


.nclex-container{
    //background: #ff0000;
    min-height: 100%;

    .category-list{
        //background: #00ff00;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        padding: 26px 0px 30px 0px;

        .category-item{
            position: relative;
            background: #ccc;
            width: 30%;
            margin: 1.6% 1.6%;
            height: 160px;
            border-radius: 6px;
            overflow: hidden;

            .item-content{
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: rgba(0, 0, 0, 0.7);

                .header{
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 10px;

                    .title{
                        position: relative;
                        color: #fff;
                        font-size: 14px;
                    }

                    .controls{
                        position: relative;
                        display: flex;
                        align-items: center;

                        svg{
                            position: relative;
                            width: 20px;
                            margin-right: 3px;
                            fill: #43c9db;
                        }

                        label{
                            position: relative;
                            margin: 0px;
                            color: #fff;
                        }

                        .separator{
                            position: relative;
                            background: #fff;
                            width: 1px;
                            height: 16px;
                            margin: 0px 12px;
                            opacity: 0.4;
                        }
                    }
                }


                .footer{
                    position: absolute;
                    width: 100%;
                    bottom: 0px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), transparent);
                    padding-bottom: 7px;

                    .progress-bar{
                        position: relative;
                        flex: 1 1;
                        margin: 0px 0px 0px 10px;
                        height: 8px;
                        background: #625e5e;
                        border-radius: 4px;
                        overflow: hidden;

                        .progress{
                            position: absolute;
                            background: #43c9db;
                            height: 100%;
                            width: 0%;
                        }
                    }

                    .controls{
                        position: relative;

                        .btn{
                            position: relative;
                            width: 55px;
                            text-align: center;
                            cursor: pointer;
                            line-height: 8px;
                            margin: 0px 10px;
                            border-radius: 14px;
                            color: #fff;
                            background: #9f9c9c;
                            display: block;
                        }

                        .btn:hover{
                            background: #716faf;
                        }
                    }

                }
            }

            .item-bg{
                background: #ccc;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                background-size: cover !important;
                background-position: center center !important;
            }
        }

    }

}


@media (max-width: 600px){
    .nclex-container{
       position: relative;

       .category-list{
           position: relative;

           .category-item{
                width: 100%;
           }
       }
    }
}


.pdf-reader-container{
    background: #e4e4e4;
    padding: 30px 30px;

    .react-pdf__Page{
        display: flex;
        justify-content: center;
        padding-bottom: 30px;

        canvas{
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
        }
    }

    .react-pdf__Page:last-child{
        padding-bottom: 0px;
    }

}


@media (max-width: 650px){
    .pdf-reader-container{

        .react-pdf__Page{

            canvas{
                width: 90vw !important;
                height: 135vw !important;
            }
        }
    }
}


.range-list{
    position: absolute;
    width: 100%;
    height: 100%;

    .range-item{
        border-bottom: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        font-size: 15px;
        padding: 0px 20px;
        justify-content: space-between;

        .left{
            position: relative;
            display: flex;
            align-items: center;

            .name{
                font-weight: bold;
                width: 200px;
                text-transform: capitalize;
            }

            .value-range{
                position: relative;
                background: #fff;
                border-left: 4px solid #2498a7;
                padding: 6px 12px;
                font-size: 12px;
            }
        }

        .right{
            position: relative;
            display: flex;
            align-items: center;

            .score{
                position: relative;
                color: #466694;
                padding: 8px 16px;
                cursor: pointer;
                border-radius: 20px;
                font-size: 12px;
                margin-left: 18px;
                border: 1px solid #466694;
                background: #fff;
            }

            .btn{
                position: relative;
                background: #466694;
                color: #fff;
                padding: 8px 12px;
                cursor: pointer;
                border-radius: 20px;
                font-size: 12px;
                margin-left: 12px;
            }

            .btn:hover{
                background: #6a8fc5;
            }
        }
    }
}


.range-question-container{
    background: #f9f9f9;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .close-btn{
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;

        svg{
            fill: #afafaf;
        }
    }

    .range-question{
        position: relative;
        text-align: center;

        .name{
            position: relative;
            font-size: 54px;
            font-weight: bold;
            border-bottom: 2px solid #00baff;
            padding: 8px 56px;
            margin-bottom: 12px;
            text-transform: capitalize;
        }

        .value{
            position: relative;
            font-size: 40px;
        }
    }

    .choice-list{
        position: relative;
        display: flex;
        margin-top: 60px;

        .choice-item{
            position: relative;
            display: flex;
            background: #fff;
            border: 1px solid #b0b0b4;
            width: 130px;
            height: 80px;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            margin: 0px 12px;
            font-size: 18px;
            cursor: pointer;
        }

        .choice-item:hover{
            background: #cbebff;
        }

        .correct{
            background: #7bd87b !important;
            color: #fff;
        }

        .incorrect{
            background: #d87b7b !important;
            color: #fff;
        }

    }

}


@media (max-width: 650px){
    .range-list{
        position: relative;

        .range-item{
            flex-direction: column;
            height: auto;
            padding: 10px 20px;

            .left{
                justify-content: space-between;
                width: 100%;

                .value-range{
                    white-space: nowrap;
                    background: #f7f7f7;
                }

            }

            .right{
                width: 100%;
                justify-content: right;
                margin-top: 10px;
            }
        }
    }

    .range-question-container{
        position: absolute;

        .close-btn{
            top: 20px;
            right: 20px;
        }

        .range-question{
            position: relative;

            .name{
                font-size: 45px;
            }

            .value{
                font-size: 35px;
            }
        }

        .choice-list{
            position: relative;

            .choice-item{
                margin: 0px 6px;
                width: 100px;
                height: 60px;
            }

            .choice-item:hover{
                background: #ffffff;
            }

        }

    }

}
